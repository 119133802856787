/**
 * Timetastic
 * Discounts page
 */
import React, { useEffect } from "react"

// Helpers
import { animate, trackCta } from "src/helpers"
import { doSignupClick } from "../helpers/analytics"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/discounts.scss"
import "src/styles/animations/discounts.scss"

// SEO
const title = "Timetastic for good causes. Timetastic discounts."
const description =
  "Timetastic Discounts - 50% off Timetastic for Registered Charities, Certified B Corporations, Fair Tax Mark holders, 1% for the Planet members and NHS Trusts."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLangs={{
          EnHref: "https://timetastic.co.uk/discounts/",
          UsaHref: "https://timetastic.us/discounts/",
          UkHref: "https://timetastic.co.uk/discounts/",
        }}
      />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-discounts-main">
          <section className="c-section u-text-centre u-text-left-mobile c-discounts-hero">
            <div className="u-inner c-discounts-hero__inner is-unanimated">
              <h1 className="h h2 c-discounts-hero__title">
                We want to help{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">good causes</span>
              </h1>
              <div className="u-inner u-inner--800">
                <p>
                  If you're a charity or the kind of organisation making a
                  positive impact on the environment or communities, or you're
                  focused on making the world a better place for the next
                  generation, then we'd like to offer you a discount.
                </p>
              </div>
            </div>
          </section>
          <section className="c-section c-discounts-cards">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated">
                <div className="c-discounts-card">
                  <div className="c-discounts-card__icon is-unanimated">
                    <img
                      loading="eager"
                      alt="Charities"
                      src="/images/pricing/charity-icon.svg"
                      width="150"
                    />
                  </div>
                  <div className="c-discounts-card__top">
                    <h2 className="h h3 c-discounts-card__title">
                      Registered Charities
                    </h2>
                    <div className="c-discounts-card__desc">
                      <p>
                        We know that budgets can be really tight so we offer a
                        discount to registered charities, to help them to put
                        more into the causes that matter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="is-unanimated">
                <div className="c-discounts-card">
                  <div className="c-discounts-card__icon is-unanimated">
                    <img
                      loading="eager"
                      alt="Certified B Corps"
                      src="/images/discounts/b-corps-logo.svg"
                      width="150"
                    />
                  </div>
                  <div className="c-discounts-card__top">
                    <h2 className="h h3 c-discounts-card__title">
                      Certified B Corporations
                    </h2>
                    <div className="c-discounts-card__desc">
                      <p>
                        Certified B Corporations are a new kind of business that
                        are legally required to consider the impact of their
                        decisions on their workers, customers, suppliers,
                        community, and the environment.
                      </p>
                      <p>
                        <a
                          className="u-em-link"
                          href="https://bcorporation.uk"
                          target="blank"
                          rel="nofollow noreferrer noopener"
                          onClick={() => {
                            trackCta("BCorp")
                          }}
                        >
                          <span>
                            Find out more about Certified B Corporations
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="is-unanimated">
                <div className="c-discounts-card">
                  <div className="c-discounts-card__icon is-unanimated">
                    <img
                      loading="lazy"
                      alt="Fair Tax Mark"
                      src="/images/discounts/fair-tax-logo.svg"
                      width="200"
                    />
                  </div>
                  <div className="c-discounts-card__top">
                    <h2 className="h h3 c-discounts-card__title">
                      Fair Tax Mark
                    </h2>
                    <div className="c-discounts-card__desc">
                      <p>
                        The Fair Tax Mark certification scheme seeks to
                        encourage and recognise organisations that pay the right
                        amount of corporation tax at the right time and in the
                        right place.
                      </p>
                      <p>
                        <a
                          className="u-em-link"
                          href="https://fairtaxmark.net"
                          target="blank"
                          rel="nofollow noreferrer noopener"
                          onClick={() => {
                            trackCta("FairTax")
                          }}
                        >
                          <span>Find out more about the Fair Tax Mark</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="is-unanimated">
                <div className="c-discounts-card">
                  <div className="c-discounts-card__icon is-unanimated">
                    <img
                      loading="lazy"
                      alt="1% for the Planet"
                      src="/images/discounts/one-percent-logo.svg"
                      width="150"
                    />
                  </div>
                  <div className="c-discounts-card__top">
                    <h2 className="h h3 c-discounts-card__title">
                      1% for the Planet
                    </h2>
                    <div className="c-discounts-card__desc">
                      <p>
                        1% for the Planet is a global movement inspiring
                        businesses and individuals to support environmental
                        solutions through annual memberships and everyday
                        actions.
                      </p>
                      <p>
                        <a
                          className="u-em-link"
                          href="https://www.onepercentfortheplanet.org"
                          target="blank"
                          rel="nofollow noreferrer noopener"
                          onClick={() => {
                            trackCta("OnePerCentForThePlanet")
                          }}
                        >
                          <span>Find out more about 1% for the Planet</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="is-unanimated">
                <div className="c-discounts-card">
                  <div className="c-discounts-card__icon is-unanimated">
                    <img
                      loading="lazy"
                      alt="NHS Trusts"
                      src="/images/discounts/nhs-logo.svg"
                      width="150"
                    />
                  </div>
                  <div className="c-discounts-card__top">
                    <h2 className="h h3 c-discounts-card__title">NHS Trusts</h2>
                    <div className="c-discounts-card__desc">
                      <p>
                        We believe that the best healthcare service is a
                        publicly funded one. We are proud of our NHS and
                        grateful for the incredible work it does.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="is-animated c-section c-free-trial is-unanimated">
            <div className="u-inner">
              <h2 className="h h1 c-free-trial__title u-text-center u-text-left-mobile">
                Half price Timetastic
              </h2>
              <div className="u-inner">
                <p>
                  If your organisation is a <strong>Registered Charity</strong>,{" "}
                  <strong>Certified B Corporation</strong>,{" "}
                  <strong>Fair Tax Mark holder</strong>,{" "}
                  <strong>1% for the Planet member </strong>or an{" "}
                  <strong>NHS Trust</strong>, then we'd like to offer you a 50%
                  discount on our standard pricing.
                </p>
                <p>
                  Once you have{" "}
                  <a
                    className="u-em-link"
                    href="#"
                    onClick={() => {
                      trackCta("DiscountsSignUp")
                      doSignupClick()
                    }}
                  >
                    <span>signed up</span>
                  </a>{" "}
                  and checked that Timetastic works for you,{" "}
                  <a
                    className="u-em-link"
                    href="mailto:hello@timetastic.co.uk?subject=Charity %26 Good Cause Discount"
                    onClick={() => {
                      trackCta("DiscountsEmail")
                    }}
                  >
                    <span>send us an email</span>
                  </a>{" "}
                  and we'll check your registration with the relevant body and
                  adjust your price accordingly.
                </p>
              </div>
              <div className="u-text-center u-text-left-mobile">
                <a
                  className="c-button c-button--xl c-button--primary"
                  href="mailto:hello@timetastic.co.uk?subject=Charity %26 Good Cause Discount"
                  onClick={() => {
                    trackCta("DiscountsEmailButton")
                  }}
                >
                  Apply for your discount
                </a>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
